<template>
  <div class="casino-menu" data-t="casino-menu">
    <StMobileMenuItem
      v-for="category in extendedTopCategories"
      :key="category.code"
      :title="category.name"
      :icon-raw="category.icon"
      :icon="category.iconName"
      :link="category.link || `/casino/categories/${category.code}`"
      :data-t="`menu-item-${category.code}`"
    />
    <div class="section-title">
      {{ t('menuMobile.gameSelections') }}
    </div>
    <StMobileMenuItem
      v-for="category in bottomCategories"
      :key="category.code"
      :title="category.name"
      :icon-raw="category.icon"
      :link="`/casino/categories/${category.code}`"
      :data-t="`menu-item-${category.code}`"
    />
    <StMobileMenuItem
      :title="t('menu.providers')"
      icon="providers"
      :level="2"
      link="/casino/providers"
      data-t="menu-item-providers"
    />
  </div>
</template>

<script setup lang="ts">
import StMobileMenuItem from '@st/ui/components/StMobileMenuItem/StMobileMenuItem.vue'
import { useCasinoMenu } from './useCasinoMenu'

const { t } = useI18n()
const { extendedTopCategories, bottomCategories } = useCasinoMenu()
</script>

<style scoped>
.section-title {
  padding: var(--spacing-400) var(--spacing-200) var(--spacing-075)
    var(--spacing-200);
  font: var(--mobile-text-medium);
  color: var(--text-tertiary);
}
</style>
