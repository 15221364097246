<template>
  <Transition name="bar">
    <div v-if="outcomeCount > 0" class="m-coupon-bar" data-t="m-coupon-bar">
      <div :key="outcomeCount" class="shimmer" />
      <div class="counter-wrapper">
        <div class="counter-container">
          <Transition name="roll">
            <div :key="outcomeCount" class="counter">
              {{ outcomeCount }}
            </div>
          </Transition>
        </div>
      </div>
      <div class="text">
        {{ t('coupon.events', outcomeCount) }}
      </div>
      <div class="odds-wrapper">
        <Transition name="roll">
          <div :key="odds" class="odds">
            {{ odds }}
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { truncRate } from '@st/utils'
import { useCouponStore } from '../../stores/useCouponStore'

const { t } = useI18n()

const { outcomes, outcomeCount } = storeToRefs(useCouponStore())

const odds = computed(() =>
  truncRate(
    outcomes.value
      .reduce((acc, outcome) => acc.times(outcome.outcome.odds), new Decimal(1))
      .toNumber(),
  ),
)
</script>

<style scoped>
.m-coupon-bar {
  position: sticky;
  z-index: 5;
  bottom: 48px;

  overflow: hidden;
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  height: 48px;
  padding: var(--spacing-125) var(--spacing-150);

  font: var(--mobile-text-semibold);

  background: radial-gradient(ellipse at 100% 100%, #ffb387, #fe671000), #ff4d00;
}

.text {
  position: relative;
}

.counter-wrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 32px;
  min-height: 30px;
  padding: var(--spacing-075) var(--spacing-125);

  font: var(--mobile-text-semibold);

  border: var(--border-width-medium, 1px) solid rgb(255 255 255 / 48%);
  border-radius: var(--border-radius-075);
}

.counter-container {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  height: 1em;
}

.odds-wrapper {
  position: relative;

  overflow: hidden;
  flex-grow: 1;

  height: 24px;
  margin-right: -10px; /* stylelint-disable-line */
  padding-right: 10px; /* stylelint-disable-line */
}

.odds {
  position: absolute;
  right: 10px;

  font-family: var(--sf-pro-display);
  font-size: 20px; /* stylelint-disable-line */
  font-weight: 800; /* stylelint-disable-line */
  font-style: italic; /* stylelint-disable-line */
  line-height: 24px; /* stylelint-disable-line */
  text-align: right; /* stylelint-disable-line */
  letter-spacing: -0.6px;
}

.roll-enter-active {
  transition: transform 0.2s;
}

.roll-leave-active {
  position: absolute;
  transition: transform 0.2s;
}

.roll-enter-from {
  transform: translateY(1em);
}

.roll-leave-to {
  transform: translateY(-1em);
}

.bar-enter-active,
.bar-leave-active {
  transition: transform 0.2s ease-in-out;
}

.bar-enter-from {
  transform: translateY(48px);
}

.bar-leave-to {
  transform: translateY(48px);
}

.shimmer {
  position: absolute;
  left: -430px;

  width: 406px;
  height: 99px;

  background: #ff964b;
  filter: blur(20px);
  border-radius: 888px; /* stylelint-disable-line */

  animation: coupon-shimmer 0.3s ease-out;
}

@keyframes coupon-shimmer {
  100% {
    transform: translateX(calc(100vw + 460px));
  }
}
</style>
