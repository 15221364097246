<template>
  <form
    v-if="outcome"
    class="quick-bet-form"
    data-t="quick-bet-form"
    @submit.prevent="handleSubmit"
  >
    <OutcomeDetails :outcome="outcome" />
    <BetAmountInput
      v-model="amount"
      class="amount-input"
      :currency-icon="currencyIcon"
      :max-amount="maxAmount"
      :freebet="freebet"
      :show-max-button="isAuthenticated"
      :error="!!amountError"
      :error-message="amountError"
    />
    <FreebetsSelect
      v-if="notUsedFreebets.length"
      v-model="freebet"
      class="freebets-select"
      :bet-rate="outcome.outcome.odds.toString()"
      bet-type="ordinary"
    />
    <QuickBetSummary
      :amount="freebet ? freebet.amount : amount"
      :outcome="outcome"
      :currency-icon="currencyIcon"
      class="summary"
    />
    <StTransitionExpand>
      <p v-if="betError" class="bet-error">
        {{ betError }}
      </p>
    </StTransitionExpand>

    <MNotEnoughtError
      v-if="isNegativeBallanceError"
      class="balance-error"
      @on-close="handleCloseNegativeError"
    />

    <StButton
      block
      type="primary"
      submit
      class="submit-button"
      :label="t('coupon.makeBet')"
      :loading="isLoading"
    />
  </form>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import Decimal from '@st/decimal'
import type { Freebet } from '@st/bonuses/types'
import { useFreebetsStore } from '@st/bonuses/stores/useFreebetsStore'
import { useCouponStore } from '../../stores/useCouponStore'
import QuickBetSummary from './QuickBetSummary.vue'
import { useLocalizeBetError } from '../../composables/useLocalizeBetError'
import { useValidateBetAmount } from '../../composables/useValidateBetAmount'
import { useLimits } from '../../composables/useLimits'
import { useNotEnoughtError } from '../../composables/useNotEnoughtError'
import MNotEnoughtError from '../NotEnoughtError/MNotEnoughtError.vue'

const { t } = useI18n()
const toast = useToast()
const router = useRouter()

const couponStore = useCouponStore()
const { outcomes, isLoading } = storeToRefs(couponStore)
const freebetsStore = useFreebetsStore()
const { notUsedFreebets } = storeToRefs(freebetsStore)

const { isAuthenticated } = storeToRefs(useUserStore())
const { activeAccount } = storeToRefs(useAccountsStore())

watchEffect(() => {
  if (isAuthenticated.value) freebetsStore.fetchFreebets()
})

const outcome = computed(() => outcomes.value?.[0])
const amount = ref('')
const freebet = ref<Freebet>()

const currencyIcon = computed(() => {
  if (!isAuthenticated.value || !activeAccount.value)
    return 'c-crypto-icon-usdt'
  if (freebet.value) return freebet.value.currencyIcon
  return activeAccount.value?.icon
})

const { ordinaryLimitsByOutcome } = useLimits({
  outcomes,
  currencyId: computed(() => activeAccount.value?.currencyId ?? 1),
})

const betLimit = computed(
  () => ordinaryLimitsByOutcome.value[outcome.value.uniqId],
)

const maxAmount = computed(() =>
  Decimal.min(betLimit.value.max, activeAccount.value?.balance ?? 0).toString(),
)

const { createBet } = useCreateBet()

const amountError = ref('')

watch(amount, () => {
  amountError.value = ''
})

const betError = ref('')
const { isNegativeBallanceError, betErrorType, closeNegativeBallanceError } =
  useNotEnoughtError()

const localizeBetError = useLocalizeBetError()
const validateBetAmount = useValidateBetAmount()

function validate() {
  if (!isAuthenticated.value) {
    router.replace({ query: { modal: 'login' } })
    return false
  }

  if (!outcome.value.isActive) {
    betError.value = t('coupon.errors.marketNotActive')
    return false
  }

  if (freebet.value) return true

  const { isValid, error, errorType } = validateBetAmount({
    amount: amount.value,
    balance: activeAccount.value.balance,
    limit: betLimit.value,
  })

  if (!isValid) {
    amountError.value = error ?? ''
    betErrorType.value = errorType
    return false
  }

  return true
}

async function handleSubmit() {
  const isValid = validate()
  if (!isValid) return

  isLoading.value = true

  const result = freebet.value
    ? await createBet({
        betType: 'ordinary',
        outcomes: [outcome.value],
        freebet: freebet.value,
      })
    : await createBet({
        betType: 'ordinary',
        outcomes: [outcome.value],
        amount: amount.value,
        accountId: activeAccount.value.id,
      })

  isLoading.value = false

  if (result.isAccepted) {
    toast.open({
      label: t('coupon.betIsAccepted'),
      type: 'positive',
    })
    couponStore.clear()
  } else {
    toast.open({
      label: t('coupon.betIsNotAccepted'),
      type: 'negative',
    })
    betError.value = localizeBetError(result.error)
  }
}

const emits = defineEmits<{
  (e: 'close'): void
}>()
async function handleCloseNegativeError() {
  closeNegativeBallanceError()
  emits('close')
}
</script>

<style scoped>
.quick-bet-form {
  padding: 0 var(--spacing-200) var(--spacing-200);
}

.freebets-select {
  margin: var(--spacing-125) 0 0;
}

.amount-input {
  margin-top: var(--spacing-100);
}

.submit-button {
  margin-top: var(--spacing-025);
}

.submit-button.loading {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 100vmax rgba(0 0 0 / 60%);
}

.bet-error {
  margin: 0 0 var(--spacing-100);
  padding: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.balance-error {
  margin-top: var(--spacing-025);
  margin-bottom: var(--spacing-150);
}
</style>
